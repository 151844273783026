<template lang="html">
  <h2>oi</h2>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>
